<template>
  <cw-page
    icon="assignment"
    class="confirmation__car-loan"
  >
    <template #page-title>
      <translate>
        Confirm loan agreement
      </translate>
    </template>
    <validation-observer
      ref="observer"
      autocomplete="off"
      tag="v-form"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <cw-confirmation-summary/>
        </v-col>
        <v-col
          class="mt-md-10"
          cols="12"
          md="6"
        >
          <cw-confirmation-terms/>
          <cw-confirmation-pep :form="form"/>
        </v-col>
      </v-row>
    </validation-observer>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :disabled="$wait.waiting('SUBMIT_FORM') || $wait.waiting('page__init')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        :dark="!$wait.waiting('SUBMIT_FORM') && !$wait.waiting('page__init')"
        color="purple-one"
        @click="
          validateAndSubmit();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Approve loan offer
        </translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { goToError } from '@shared/mixins';

export default {
  name: 'CwConfirmationCarLoan',

  components: {
    'cw-confirmation-summary': () => import('@fi/pages/steps/ConfirmationSummary'),
    'cw-confirmation-terms': () => import('@fi/pages/steps/ConfirmationTerms'),
    'cw-confirmation-pep': () => import('@fi/pages/steps/ConfirmationPEP'),
    'validation-observer': ValidationObserver,
  },

  mixins: [goToError],

  data() {
    return {
      form: {
        politicallyExposedPerson: 'off',
      },
    };
  },

  computed: {
    ...mapGetters({
      locale: 'application/locale',
      offer: 'application/getSelectedOffer',
    }),

    ...mapState({
      terms: state => state.application.terms,
    }),
  },

  created() {
    this.setSummary();
  },

  methods: {
    ...mapActions({
      setApplicationSummary: 'application/setApplicationSummary',
      submitForm: 'application/submit',
      acceptTerms: 'application/acceptTerms',
    }),

    setSummary() {
      const applicationSummary = [{
        name: this.$gettext('Loan amount'),
        value: this.$filters.currency(this.offer.loanLimit, {
          locale: this.locale,
        }),
      }, {
        name: this.$gettext('Monthly Instalment'),
        value: this.$filters.currency(this.offer.instalmentAmount, {
          locale: this.locale,
        }),
      }, {
        name: this.$gettext('Number of monthly installments'),
        value: this.offer.numberOfInstalments,
      }, {
        name: this.$gettext('First due date'),
        value: this.$filters.date(this.offer.firstDueDate, {
          type: 'date',
          locale: this.locale,
        }),
      }, {
        name: this.$gettext('Nominal interest'),
        value: this.$filters.percent(Number(this.offer.interestRate)),
      }, {
        name: this.$gettext('The annual percentage rate'),
        value: this.$filters.percent(Number(this.offer.annualPercentageRate)),
      }, {
        name: this.$gettext('Total interest'),
        value: this.$filters.currency(this.offer.instalmentTotals.interest, {
          locale: this.locale,
        }),
      }, {
        name: this.$gettext('Total loan management costs'),
        value: this.$filters.currency(this.offer.instalmentTotals.fees, {
          locale: this.locale,
        }),
      }, {
        name: this.$gettext('Total amount due'),
        value: this.$filters.currency(this.offer.instalmentTotals.instalmentAmount, {
          locale: this.locale,
        }),
      }];

      this.setApplicationSummary(applicationSummary);
    },

    async validateAndSubmit() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        this.goToError();

        return;
      }

      await this.submit();
    },

    async submit() {
      this.$wait.start('SUBMIT_FORM');

      await Promise.all(
        this.terms.map(term => this.acceptTerms({
          termsName: term.name,
        })),
      );

      const formParams = {
        politicallyExposedPerson: this.form.politicallyExposedPerson,
      };

      const routineParams = {
        uri: 'executeRoutine',
        routine: 'Submit Car Loan Terms',
      };

      try {
        await this.submitForm({
          formParams,
          routineParams,
        });
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>
